<template>
  <div class="app">
    <Navbar :title="$t('addBank.title')" />
    <div class="list" pullTips="false">
      <div class="list_item">
        <div class="label">{{ $t('addBank.name') }}:</div>
        <van-field v-model="formData[bankType].name" :placeholder="$t('addBank.namePlaceholder')" />
      </div>
      <div class="list_item">
        <div class="label">{{ $t('My.PersonalInformation.BankAccounts.BankName') }}:</div>
        <!-- <div class="van-cell van-field" v-if="bankType === 'bank' && !verConfig.banks">
          <div class="van-cell__value van-field__value" @click="actionShow = true">
            <div class="van-field__body">
              <div class="van-field__control">
                {{ bankItem }}
              </div>
            </div>
          </div>
        </div> -->
        <van-field v-model="formData[bankType].bank" :placeholder="$t('addBank.banknamePlaceholder')" />
      </div>
      <div class="list_item">
        <div class="label">{{ $t('Home.WithdrawSu.Bank') }}:</div>
        <van-field v-model="formData[bankType].card" :placeholder="$t('addBank.bankcardPlaceholder')" />
      </div>
      <div class="list_item" style="padding-bottom: 0;">
        <div class="label">{{ $t('addBank.withdrawalPassword') }}:</div>
        <van-field v-model="formData[bankType].paypass" :placeholder="$t('addBank.withdrawalPasswordPlaceholder')" />
      </div>
      <van-popup v-if="!!verConfig.banks" v-model="show" round position="bottom">
        <van-cascader v-model="formData[bankType].bank" :title="$t('newAdd.BankName')" :options="mxhBankArr"
          v-if="country != 'tr'" :placeholder="$t('newAdd.BankName')" @change="cascaderChange" @close="show = false" />
        <van-cascader v-model="formData[bankType].bank" :title="$t('newAdd.BankName')" :options="trBankArr"
          v-if="country == 'tr'" active-color="var(--theme)" :placeholder="$t('newAdd.BankName')"
          @change="cascaderChange" @close="show = false" />
      </van-popup>
      <div>
        <span class="list-red">
          {{ verConfig.needBank
            ? $t("newAdd.AddBankCardNote")
            : $t("newAdd.AddWalletNote")
          }}
        </span>
      </div>
      <button @click="itemClick()" class="button">
        {{ $t("newAdd.Submit") }}
      </button>
    </div>
    <van-action-sheet v-model="actionShow">
      <van-picker show-toolbar :columns="bankList" :confirm-button-text="$t('btn.confirm')"
        :cancel-button-text="$t('btn.cancel')" @confirm="onConfirm" @cancel="onCancel" value-key="value" />
    </van-action-sheet>
  </div>
</template>
<script>
import { GET_USER_BANK_INFO, COMMIT_USER_BANK_INFO, BANK_LIST } from "@/api";
import { count } from "@/utils";
import { maskMiddle } from "@/utils/common"
import Cookies from "js-cookie";
import { NavBar, Dialog, Toast, Cascader, Popup, Field, Tab, Tabs, Picker, ActionSheet } from "vant";
import "vant/lib/button/style";
import verConfig from "@/utils/verConfig";
import Navbar from "@/components/JNav";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Navbar,
    [Picker.name]: Picker,
    [ActionSheet.name]: ActionSheet
  },
  data() {
    this.paymentType = "cryptocurrency";
    this.bank = "USDT";
    return {
      actionShow: false,
      show: false,
      country: Cookies.get("language"),
      bankshowVal: "",
      dataid: 0,
      input_name: "",
      input_bank: "",
      input_card: "",
      input_open: "",
      mobile: "",
      upi: "",
      email: "",
      ifsc_code: "",
      bankType: verConfig.needBank ? "bank" : "coin",
      trBankArr: [
        {
          value: "Garanti-Bank",
          text: "Garanti Bankası",
        },
        {
          value: "is-Bank",
          text: "İş Bankası",
        },
        {
          value: "Vakifbank",
          text: "Vakıfbank",
        },
        {
          value: "Halk-Bank",
          text: "Halk Bankası",
        },
        {
          value: "Denizbank",
          text: "Denizbank",
        },
        {
          value: "Akbank",
          text: "Akbank",
        },
        {
          value: "Turkiye-Finans",
          text: "Türkiye Finans Bank",
        },
        {
          value: "INGBank",
          text: "INGBank",
        },
        {
          value: "Kuveytturk",
          text: "Kuveyttürk",
        },
        {
          value: "Ziraat-Bank",
          text: "Ziraat Bankası",
        },
        {
          value: "Yapi-Kredi",
          text: "Yapı Kredi",
        },
        {
          value: "Sekerbank",
          text: "Şekerbank",
        },
        {
          value: "Albaraka",
          text: "Albaraka",
        },
        {
          value: "Aktif-Bank",
          text: "Aktif Bank",
        },
      ],
      mxhBankArr: verConfig.banks || [],
      coinTypeArr: [{ value: "TRC20", text: "TRC20" }],
      coinTypeShow: "",
      input_coin_type: "",
      coinCascader: false,
      bankData: {},
      coinData: {},
      formData: {
        bank: {},
        coin: {},
      },
      bankList: [],
      walletID: "",
      bankItem: ''
    };
  },
  mounted() {
    const { id } = this.$route.query;
    if (!!id) {
      this.walletID = id;
    }
    this.getUserBankInfo();
  },
  created() {
    this.getBank();
  },
  methods: {
    focusChange() {
      this.formData[this.bankType].new_card = this.formData[this.bankType].card
    },
    blurChange() {
      this.formData[this.bankType].new_card = maskMiddle(this.formData[this.bankType].card)
      console.log(this.formData);
    },
    inputChange(item) {
      this.formData[this.bankType].card = item
    },
    async getBank() {
      try {
        const res = await BANK_LIST();
        const result = Object.entries(res.data).map(([type, value]) => ({ type, value }))
        this.bankList = result;
      } catch (error) {
        console.log(error);
      }
    },
    onConfirm(value, index) {
      this.formData.bank.bank = value.type;
      this.bankItem = value.value;
      this.actionShow = false;
    },
    onCancel() {
      this.actionShow = false
    },
    getType(item) {
      const itmes = this.bankList.find(i => i.type == item);
      return itmes?.value || ''
    },
    formDataInit() {
      if (this.bankType === "bank") {
        this.$map.call(
          this.country === "tr" ? this.trBankArr : this.mxhBankArr,
          (item) => {
            if (this.formData.bank.bank === item.value) {
              this.bankshowVal = item.text;
            }
          }
        );
      } else {
        this.$map.call(this.coinTypeArr, (item) => {
          if (this.formData.coin.open === item.value)
            return (this.coinTypeShow = item.text);
        });
      }
    },
    coinTypeChange({ selectedOptions, value }) {
      this.coinCascader = false;
      this.coinTypeShow = selectedOptions[0].text;
    },
    cascaderChange(item) {
      this.show = false;
      this.bankshowVal = item.selectedOptions[0].text;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    typeChange(name, title) {
      this.formDataInit();
      if (name === "coin") {
        this.formData.coin.bank = "USDT";
      }
    },
    itemClick() {
      const items = this.formData[this.bankType]
      if (!items.name) return this.$toast(this.$t('addBank.namePlaceholder'));
      if (!items.bank) return this.$toast(this.$t('addBank.banknamePlaceholder'));
      if (!items.card) return this.$toast(this.$t('addBank.bankcardPlaceholder'));
      if (!items.paypass) return this.$toast(this.$t('addBank.withdrawalPasswordPlaceholder'));
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.formData.coin.bank = "USDT";
      // const res = this.getType(this.bankItem);
      COMMIT_USER_BANK_INFO(this.formData[this.bankType]).then((r) => {
        Toast.clear();
        if (r.data.ret === 1) {
          Toast.clear();
          Dialog.alert({
            title: this.$t("newAdd.warmTitle"),
            message: r.data.msg,
            confirmButtonText: this.$t("My.Button.Two"),
            cancelButtonText: this.$t("My.Button.One"),
          }).then(() => {
            const { data } = r.data;
            this.$cookie.set("selectBankInfo", JSON.stringify(data));
            this.$router.go(-1);
          });
        } else {
          Toast.clear();
          Toast(r.data.msg);
        }
      }).catch((e) => {
        Toast.clear();
        console.error(e);
      });
    },
    getUserBankInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO().then((r) => {
        Toast.clear();
        const { data } = r.data;
        if (r.data.ret === 1 && data.length > 0) {
          Toast.clear();
          const params = this.$route.query;
          const bankId = params.id || data[0].id;
          this.$map.call(data, (item, index) => {
            if (bankId == item.id) {
              if (!!item.open && item.open !== "bank") {
                this.bankType = "coin";
              } else {
                this.bankType = "bank";
              }
            }
            if (!!item.open && item.open !== "bank") {
              this.formData.coin = item;
              this.formDataInit();
            } else {
              this.formData.bank = { ...item, new_card: maskMiddle(item.card) };
              this.bankItem = this.getType(item.bank)
              this.formDataInit();
            }
            return;
          });
        }
      }).catch((e) => {
        Toast.clear();
        console.error(e);
      });
    },
  },
};
</script>

<style scoped lang="less">
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: var(--bg1);

.app {
  min-height: 100vh;
  background: var(--bg);

  .list {
    padding: calc(15rem / 16);

    &_item {
      padding-bottom: calc(30rem / 16);

      .label {
        font-size: calc(18rem / 16);
        font-weight: 500;
        color: var(--light);
        margin-bottom: calc(15rem / 16);
      }

      /deep/ .van-cell {
        background: #132235;

        input::placeholder {
          color: #c0c4cc;
        }

        input {
          color: var(--light);
        }
      }

      .van-field__control {
        height: 24px;
        color: var(--light);
        font-size: 14px;
      }
    }
  }
}

.list-red {
  margin-top: calc(62rem / 16);
  color: var(--theme);
  font-size: calc(14rem / 16);
  text-align: center;
  display: block;
}

.list-line {
  width: 100%;
  height: 1px;
  background-color: var(--borderColor);
}

.button {
  width: 100%;
  height: calc(43rem / 16);
  line-height: calc(43rem / 16);
  font-size: calc(14rem / 16);
  margin: 0 auto;
  margin-top: calc(11rem / 16);
  background: linear-gradient(179deg, #13a2ba, #087c95);
  border-radius: calc(8rem / 16);
  color: var(--light);
  border: none;
  display: block;
}
</style>